import { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import {
  getTrackingEvent,
  postOfferView,
  postTagEmailOffer,
} from "../../../../services/offers/offers.service";
import {
  CampaignType,
  Offer,
  OffersIdSlots,
  PlacementStage,
  QueryPostOfferView,
  QuerySaveEmail,
  RedeemBy,
  Template,
  TemplateOptionSettings,
} from "../../../../types/global.types";
import OfferItem from "../offer-item/OfferItem.component";
import { EMAIL_REGEX } from "../../../../utility/regexp";

interface Props {
  settings: TemplateOptionSettings;
  settingsTemplate: Template;
  campaign: CampaignType;
  displayOnly: boolean;
  campaignId: string;
  placementId: string;
  defaultOpen: boolean;
  placementName: string;
  isInline?: boolean;
  offers: Offer[];
  disabled: boolean;
  preEmail: string | undefined;
  setPreEmail?: (email: string) => void;
  openSingle: (
    o: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number
  ) => void;
  onAdd: (offer: Offer, value: boolean, slot: number) => void;
  selectedEmailOfferId: string;
  setSelectedEmailOfferId: (value: string) => void;
}

const SwiperComponent = ({
  settings,
  preEmail,
  setPreEmail,
  campaign,
  offers,
  disabled,
  displayOnly,
  defaultOpen,
  campaignId,
  placementId,
  placementName,
  settingsTemplate,
  openSingle,
  onAdd,
  selectedEmailOfferId,
  setSelectedEmailOfferId,
}: Props) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [eventOfferIds, setEventOfferIds] = useState<OffersIdSlots[]>([]);
  const [numberOfSlots] = useState<number>(disabled ? 0 : 3);
  const refNavPrev = useRef<HTMLDivElement | null>(null);
  const refNavNext = useRef<HTMLDivElement | null>(null);

  const [numberOfColumns, setNumberOfColumns] = useState<number>(
    Number(settings?.mainLayout.offersNumber) || 3
  );

  const [emailOffer, setEmailOffer] = useState<Offer>();
  const [indexOfEmailOffer, setIndexOfEmailOffer] = useState<number>();

  const [email, setEmail] = useState<string | undefined>(preEmail);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [sentClick, setSentClick] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(
    preEmail ? true : false
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const [emailPopulate, setEmailPopulate] = useState<boolean>(true);
  const [emailSwitch, setEmailSwitch] = useState<boolean>(
    preEmail || campaign.campaignGeneralOptions.automaticallyCheckOptIn
      ? true
      : false
  );

  const [selectedEmailOfferTitle, setSelectedEmailOfferTitle] =
    useState<string>("");
  const emailInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedEmailOfferId && emailInputRef.current) {
      const input = emailInputRef.current;
      input.focus();
      const valueLength = input.value.length; // Cursor at the end
      input.setSelectionRange(valueLength, valueLength); // Set cursor to the end
    }
  }, [selectedEmailOfferId]);

  const numberOfOffers = offers.length;

  useEffect(() => {
    setNumberOfColumns(Number(settings?.mainLayout.offersNumber) || 3);
  }, [settings]);

  const setDefaultOfferImpressionEvent = async (offers: OffersIdSlots[]) => {
    if (eventOfferIds.length < campaign.offers.length) {
      const data: QueryPostOfferView = {
        offers,
        campaignId,
        campaignName: campaign.name || "",
        companyId: campaign.company_Id,
        placementId,
        placementName,
        pageUrl: "",
        templateId: settingsTemplate.identifier,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      };

      await postOfferView(data);
    }
  };

  //set event for default active slide offers
  useEffect(() => {
    if (!defaultOpen) {
      setEventOfferIds([]);
    }
    // eslint-disable-next-line
  }, [defaultOpen]);

  //set event for default active slide offers
  useEffect(() => {
    if (swiper && defaultOpen) {
      const _ids = offers
        .map((offer: Offer, index: number) => ({
          id: offer.id,
          slot: index + 1 + numberOfSlots,
        }))
        .slice(0, 3);
      setDefaultOfferImpressionEvent(_ids);
      setEventOfferIds(_ids);
    }
    // eslint-disable-next-line
  }, [swiper, defaultOpen]);

  const isOfferSelected = (
    selectedEmailOfferId: string,
    offers: Offer[]
  ): boolean => {
    return offers.some((offer) => offer.id === selectedEmailOfferId);
  };

  const submitSaveForm = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSentClick(true);

    if (email && validEmail && emailOffer && indexOfEmailOffer !== undefined) {
      setLoading(true);
      const emailParams: QuerySaveEmail = {
        offerid: emailOffer.id,
        email: email || preEmail || "",
        offerLink: emailOffer.trackingUrl,
        companyId: campaign?.company_Id,
        placementId,
        campaignId: campaignId,
        templateId: settingsTemplate.identifier,
        slot: indexOfEmailOffer + 1 + numberOfSlots,
        orderId: emailOffer.orderId,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      };

      const response = await postTagEmailOffer(emailParams);
      getTrackingEvent({
        event: "onsendemailclick",
        retailerId: campaign?.company_Id,
        campaignId: campaignId,
        offerId: emailOffer.id,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        slot: indexOfEmailOffer + 1 + numberOfSlots,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });

      if (response?.data?.status) {
        setEmailSent(true);
        setPreEmail && setPreEmail(email || preEmail || "");
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    setEmailSent(false);
    setSentClick(false);
  }, [selectedEmailOfferId]);

  useEffect(() => {
    setEmail(preEmail);
  }, [preEmail]);

  useEffect(() => {
    const offer = offers.find((o) => o.id === selectedEmailOfferId);
    setEmailOffer(offer);
    setIndexOfEmailOffer(offers.indexOf(offer as Offer));
    // eslint-disable-next-line
  }, [selectedEmailOfferId]);

  // Validate email on change
  useEffect(() => {
    setSentClick(false);
    if (email) {
      const isValid = EMAIL_REGEX.test(email);
      setValidEmail(isValid);
    }
    // eslint-disable-next-line
  }, [email]);

  return (
    <>
      {offers.length > 0 && (
        <Swiper
          className={`swiper-container swiper-column-${numberOfColumns} ${
            offers.length < numberOfColumns ? "swiper-center" : ""
          }  ${disabled ? "swiper-disabled" : ""}`}
          modules={[Navigation]}
          slidesPerView={numberOfColumns}
          loop={numberOfOffers > numberOfColumns}
          onSwiper={(s: SwiperClass) => setSwiper(s)}
          navigation={{
            prevEl: refNavPrev.current,
            nextEl: refNavNext.current,
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = refNavPrev.current;
            swiper.params.navigation.nextEl = refNavNext.current;
          }}
          onSlidePrevTransitionEnd={(_swiper: SwiperClass) => {
            const acitveIndex = _swiper.realIndex;
            const offersLength = offers.length;
            if (offersLength >= acitveIndex - 1) {
              const offerIds = eventOfferIds.map(
                (value: OffersIdSlots) => value.id
              );
              if (!offerIds.includes(offers[acitveIndex].id)) {
                setDefaultOfferImpressionEvent([
                  {
                    id: offers[acitveIndex].id,
                    slot: acitveIndex + 1 + numberOfSlots,
                  },
                ]);
                setEventOfferIds((prevState) => [
                  ...prevState,
                  {
                    id: offers[acitveIndex].id,
                    slot: acitveIndex + 1 + numberOfSlots,
                  },
                ]);
              }
            }
          }}
          onSlideNextTransitionEnd={(_swiper: SwiperClass) => {
            const acitveIndex = _swiper.realIndex;
            const offersLength = offers.length;
            const offerCount = 3;

            if (acitveIndex === offersLength - 1) {
              const offerIds = eventOfferIds.map(
                (value: OffersIdSlots) => value.id
              );
              if (!offerIds.includes(offers[0].id)) {
                setDefaultOfferImpressionEvent([
                  { id: offers[0].id, slot: 1 + numberOfSlots },
                ]);
                setEventOfferIds((prevState) => [
                  ...prevState,
                  { id: offers[0].id, slot: 1 + numberOfSlots },
                ]);
              }
            }

            if (offersLength > acitveIndex + offerCount - 1) {
              const offerIds = eventOfferIds.map(
                (value: OffersIdSlots) => value.id
              );
              if (!offerIds.includes(offers[acitveIndex + offerCount - 1].id)) {
                setDefaultOfferImpressionEvent([
                  {
                    id: offers[acitveIndex + offerCount - 1].id,
                    slot: acitveIndex + offerCount + numberOfSlots,
                  },
                ]);
                setEventOfferIds((prevState) => [
                  ...prevState,
                  {
                    id: offers[acitveIndex + offerCount - 1].id,
                    slot: acitveIndex + offerCount + numberOfSlots,
                  },
                ]);
              }
            }
          }}
        >
          {offers.map((o: Offer, i: number) => {
            return (
              <SwiperSlide
                key={i}
                style={{ color: settings?.dealLayout.textColor }}
              >
                <OfferItem
                  offer={o}
                  slot={i + 1 + numberOfSlots}
                  settings={settings.dealLayout}
                  mainSettings={settings?.mainLayout}
                  isAdded={o.isAdded || false}
                  settingsTemplate={settingsTemplate}
                  onAdd={onAdd}
                  displayOnly={displayOnly}
                  campaign={campaign}
                  retailerId={campaign?.company_Id}
                  campaignId={campaignId}
                  placementId={placementId}
                  placementName={placementName}
                  openSingle={openSingle}
                  hasVoucher={offers.some(
                    (offer) => offer.voucher && offer.voucher.voucherType
                  )}
                  otherSettings={settings.otherSettings}
                  selectedEmailOfferId={selectedEmailOfferId}
                  setSelectedEmailOfferId={setSelectedEmailOfferId}
                  emailSent={emailSent}
                  setSelectedEmailOfferTitle={setSelectedEmailOfferTitle}
                />
              </SwiperSlide>
            );
          })}
          <div className="swiper-button-prev" ref={refNavPrev}>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17"
                r="17"
                transform="rotate(-180 17 17)"
                fill={settings.dealLayout.backgroundColor}
              />
              <rect
                x="18.6094"
                y="23"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(-135 18.6094 23)"
                fill={settings.dealLayout.textColor}
              />
              <rect
                x="20.3135"
                y="12.2188"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(135 20.3135 12.2188)"
                fill={settings.dealLayout.textColor}
              />
            </svg>
          </div>
          <div className="swiper-button-next" ref={refNavNext}>
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="17"
                cy="17"
                r="17"
                fill={settings.dealLayout.backgroundColor}
              />
              <rect
                x="15.3906"
                y="11"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(45 15.3906 11)"
                fill={settings.dealLayout.textColor}
              />
              <rect
                x="14.1719"
                y="22.2656"
                width="8.6192"
                height="1.72384"
                rx="0.86192"
                transform="rotate(-45 14.1719 22.2656)"
                fill={settings.dealLayout.textColor}
              />
            </svg>
          </div>
          {!displayOnly &&
            campaign.campaignGeneralOptions.saveOfferOptions
              .allowSavingOffers &&
            campaign.campaignGeneralOptions.saveOfferOptions.allowEmail &&
            isOfferSelected(selectedEmailOfferId, offers) && (
              <>
                <form
                  className={`super-item-email-dropdown ${
                    isLoading ? "loading-overlay" : ""
                  }`}
                  style={{
                    backgroundColor:
                      settings?.dealLayout?.emailDropdownBackground ||
                      "#ffffff",
                    border: `1px solid ${
                      settings?.mainLayout?.highlightColor || "#888888"
                    }`,
                    borderRadius: `${settings?.mainLayout?.borderRadius}px`,
                    position: "relative",
                  }}
                >
                  <div
                    className="super-desktop-minimize-email"
                    onClick={() => {
                      setSelectedEmailOfferId("");
                      setSelectedEmailOfferTitle("");
                    }}
                    style={{
                      backgroundColor: settings?.dealLayout.backgroundColor
                        ? settings?.dealLayout.backgroundColor
                        : "#e2e2e2",
                    }}
                  >
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.176625 0.34468C0.385958 0.133389 0.710902 0.109912 0.945926 0.274249L1.02944 0.34468L7.41698 6.79213L13.9706 0.178277C14.2061 -0.0594256 14.5879 -0.0594256 14.8234 0.178277C15.0327 0.389568 15.056 0.717552 14.8932 0.954775L14.8234 1.03907L8.2698 7.65293L14.5195 13.9609C14.755 14.1986 14.755 14.584 14.5195 14.8217C14.3102 15.033 13.9852 15.0565 13.7502 14.8922L13.6667 14.8217L7.41698 8.51372L1.17826 14.8118C0.942765 15.0495 0.560944 15.0495 0.325445 14.8118C0.116112 14.6005 0.0928526 14.2726 0.255667 14.0353L0.325445 13.951L6.56416 7.65293L0.176625 1.20548C-0.0588749 0.967774 -0.0588749 0.582382 0.176625 0.34468Z"
                        fill={settings?.dealLayout?.emailTextColor}
                      />
                    </svg>
                  </div>
                  {isLoading && (
                    <div className="icon-spin">
                      <svg
                        className="spin"
                        viewBox="0 0 1024 1024"
                        focusable="false"
                        data-icon="loading"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                      </svg>
                    </div>
                  )}
                  <div className="super-item-email-wrapper">
                    <div
                      className="super-item-email-title"
                      style={{
                        color:
                          settings?.dealLayout?.emailTextColor || "#888888",
                      }}
                    >
                      {settings?.dealLayout?.emailMeTheGift ||
                        "Email me the gift"}
                      {selectedEmailOfferTitle
                        ? " - " + selectedEmailOfferTitle
                        : " "}
                    </div>
                    <div className="super-item-email-input">
                      <input
                        type="text"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          setEmailSent(false);
                          setEmailPopulate(false);
                        }}
                        value={
                          emailPopulate ? preEmail || email || "" : email || ""
                        }
                        placeholder={
                          settings?.dealLayout?.enterEmail || "Enter E-mail"
                        }
                        style={{
                          borderRadius: `${settings?.dealLayout?.emailButtonRounding}px`,
                        }}
                        ref={emailInputRef}
                        autoFocus
                      />

                      <button
                        className="button"
                        style={{
                          color:
                            settings?.dealLayout?.emailButtonTextColor ||
                            "#ffffff",
                          backgroundColor:
                            settings?.dealLayout?.emailButtonBackgroundColor ||
                            "#888888",
                          borderColor:
                            settings?.dealLayout?.emailButtonBorderColor ||
                            settings?.dealLayout?.emailButtonBackgroundColor ||
                            "#888888",
                          borderRadius: `${settings?.dealLayout?.emailButtonRounding}px`,
                        }}
                        onClick={submitSaveForm}
                        disabled={!emailSwitch || !validEmail}
                      >
                        {settings?.dealLayout?.emailSend || "Send"}{" "}
                        {emailSent && !isLoading && (
                          <svg
                            width="12"
                            height="9"
                            viewBox="0 0 12 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.5667 0.28139C11.9134 0.644571 11.9 1.22002 11.5368 1.56669L4.87009 7.93033C4.51879 8.26565 3.96597 8.26565 3.61468 7.93032L0.281383 4.7485C-0.0817948 4.40183 -0.095174 3.82638 0.2515 3.4632C0.598174 3.10002 1.17362 3.08664 1.5368 3.43332L4.24239 6.01596L10.2814 0.251494C10.6446 -0.0951766 11.22 -0.0817918 11.5667 0.28139Z"
                              fill={
                                settings?.dealLayout?.emailButtonTextColor ||
                                "#ffffff"
                              }
                            />
                          </svg>
                        )}
                      </button>
                    </div>
                    {emailSent &&
                      emailOffer?.offerSettings.redeemBy ===
                        RedeemBy.webemail && (
                        <div className="label-wrapper">
                          <span className="label-success"> Email sent</span>
                        </div>
                      )}
                    {!validEmail && email && sentClick && (
                      <div className="label-wrapper">
                        <span className="label-error">
                          Must be valid email!
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="super-item-email-terms">
                    <span>
                      Your data will be processed according to BrandSwap's{" "}
                      <a
                        href="https://brandswap.com/privacy/"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {" "}
                        Privacy Policy
                      </a>
                    </span>
                  </div>
                  <div className="super-item-email-terms">
                    <label
                      htmlFor={`accept-email-${emailOffer?.id}`}
                      style={{
                        color:
                          settings?.dealLayout?.emailTextColor || "#888888",
                      }}
                    >
                      <input
                        id={`accept-email-${emailOffer?.id}`}
                        type="checkbox"
                        checked={emailSwitch}
                        onChange={(e) => setEmailSwitch(e.target.checked)}
                        className="checkbox-custom"
                      />
                      <div className="checkboxsvg"></div>I agree to share my
                      email address with BrandSwap to receive details about this
                      offer
                    </label>
                  </div>
                </form>
              </>
            )}
        </Swiper>
      )}
    </>
  );
};

export default SwiperComponent;
