import "./css/style.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import {
  ButtonShapes,
  CampaignType,
  Offer,
  LayoutClass,
  QueryParams,
  SelectedOfferStorage,
  TemplateOptionSettings,
  Template,
  PlacementStage,
  TemplateIcons,
} from "../../../types/global.types";
import { getTrackingEvent } from "../../../services/offers/offers.service";
import TemplateSingle from "../single/TemplateSingle.component";
import SwiperComponent from "./swiper/Swiper.component";
import OfferFeatureItem from "./offer-featured-item/OfferFeaturedItem.component";
import PreCountAndSelectAll from "../components/PreCountAndSelectAll/PreCountAndSelectAll";
import TemplateFilters from "../components/Filters/desktop/TemplateFilters";

interface Props {
  params: QueryParams;
  settings: TemplateOptionSettings;
  settingsTemplate: Template;
  campaignId: string;
  placementId: string;
  placementName: string;
  first: boolean;
  defaultOpen: boolean;
  campaignRes: CampaignType;
  type: LayoutClass;
  setOpen: (open: boolean) => void;
  selectedOffers: string[];
  buttonShape: ButtonShapes;
  email: string | undefined;
  slot: number;
  defaultSix: boolean;
}

const PreviewPopupSuper = ({
  params,
  email,
  settings,
  campaignId,
  first,
  campaignRes,
  placementId,
  placementName,
  defaultOpen,
  setOpen,
  selectedOffers,
  buttonShape,
  settingsTemplate,
  slot,
  defaultSix,
}: Props) => {
  const [isLoadMore, setLoadMore] = useState<boolean>(
    settings?.mainLayout?.startAsExpanded || false
  );

  const [campaign, setCampaign] = useState<CampaignType | undefined>(
    campaignRes
  );

  const [preEmail, setPreEmail] = useState<string | undefined>();

  const [single, setSingle] = useState<Offer | undefined>();
  const [isSingleSave, setSingleSave] = useState<boolean>(false);
  const [isSingleShare, setSingleShare] = useState<boolean>(false);

  const [displayOnly] = useState<boolean>(
    campaignRes?.placementSettings[0]?.displayOnly
  );
  const refOffers = useRef<HTMLDivElement | null>(null);
  const [hasOpen, setHasOpen] = useState<boolean>(!first);
  const [offerFilteredList, setOfferFilteredList] = useState<Offer[]>(
    campaignRes.offers
  );

  const [selectedEmailOfferId, setSelectedEmailOfferId] = useState<string>("");

  const updateFilteredOffers = (newOffers: Offer[], currentOffers: Offer[]) => {
    const currentOfferIds: string[] = currentOffers.map((i) => i.id);
    setOfferFilteredList(
      newOffers.filter((f) => currentOfferIds.includes(f.id))
    );
  };

  const featuredOffer = useMemo(() => {
    let _offer: Offer | undefined = offerFilteredList.find(
      (o) => o.slot === 0 && settings?.mainLayout.showFeatured
    );
    return _offer;
  }, [offerFilteredList, settings?.mainLayout.showFeatured]);

  const handleClose = () => {
    if (!settings?.mainLayout?.startAsExpanded) {
      setLoadMore(false);
    }
    setSelectedEmailOfferId("");
    window.top?.postMessage({ event: "closeMainIframe", load: "" }, "*");
    setSingle(undefined);
    setOpen(false);
    campaign &&
      getTrackingEvent({
        event: "ontemplateclose",
        retailerId: campaign.company_Id,
        campaignId,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });
    setCampaign(undefined);
  };

  const handleClickSingleWrapper = (event: React.MouseEvent<HTMLElement>) => {
    if (
      event.target instanceof Node &&
      !refOffers.current?.contains(event.target)
    ) {
      handleClose();
    }
  };

  const handleCloseSingle = () => {
    setSingle(undefined);
    setSingleSave(false);
  };

  const checkSelectedOffers = (
    selectedOffers: string[] = [],
    _campaign: CampaignType
  ) => {
    const newOffers: Offer[] = _campaign.offers.map((offer: Offer) => {
      if (selectedOffers.includes(offer.id)) {
        offer.isAdded = true;
      }
      return offer;
    });
    setCampaign(
      (prevState) => prevState && { ...prevState, offers: newOffers }
    );
  };

  const openSingle = (
    offer: Offer,
    isSave: boolean,
    isAutoEmail: boolean,
    slot: number,
    isShare?: boolean
  ) => {
    if (isAutoEmail && !single) {
      const params = {
        offer,
        isSave,
        isAuto: true,
        displayOnly,
        campaign: campaign,
        settings,
        companyId: campaign?.company_Id,
        campaignId: campaign?.id,
        placementId,
        placementName,
        slot,
      };
      window.top?.postMessage({ event: "emailPopup", load: params }, "*");
    } else {
      setSingle(offer);
      setSingleSave(isSave);
      setSingleShare(isShare || false);
      window.top?.postMessage({ event: "openSingle", offer, slot }, "*");
    }
  };

  const handleAdd = (offer: Offer, value: boolean, slot: number) => {
    setSelectedOfferStorage({
      id: offer.id,
      link: offer.trackingUrl,
      active: value,
    });
    if (campaign) {
      const newOffers: Offer[] = campaign.offers.map((_offer: Offer) => {
        if (_offer.id === offer.id) {
          _offer.isAdded = value;
        }
        return _offer;
      });
      setCampaign(
        (prevState) => prevState && { ...prevState, offers: newOffers }
      );

      updateFilteredOffers(newOffers, offerFilteredList);
    } else {
      const _single = { ...single, isAdded: value };
      setSingle((prevState) => prevState && { ...prevState, isAdded: value });
      window.top?.postMessage(
        { event: "singleChange", offer: _single, slot },
        "*"
      );
    }

    value &&
      campaign &&
      getTrackingEvent({
        event: "onselectoffer",
        offerId: offer.id,
        retailerId: campaign.company_Id,
        campaignId: campaign.id,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        slot,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });
  };

  const handleSelectAll = () => {
    if (!campaign) {
      return;
    }

    const selectedLength = offerFilteredList.filter((o) => o.isAdded).length;
    const offerLength = offerFilteredList.length;
    const shouldRemove = selectedLength === offerLength;

    offerFilteredList.forEach((offer: Offer) => {
      shouldRemove
        ? offer.isAdded && handleAdd(offer, false, offer.slot)
        : !offer.isAdded && handleAdd(offer, true, offer.slot);
    });
  };

  const setSelectedOfferStorage = (storage: SelectedOfferStorage) => {
    window.top?.postMessage({ event: "setSelectedOffer", load: storage }, "*");
  };

  useEffect(() => {
    if (defaultOpen) {
      checkSelectedOffers(selectedOffers, campaignRes);
      setCampaign(campaignRes);
      if (campaignRes && !single) {
        if (hasOpen) {
          getTrackingEvent({
            event: "onmainbuttonclick",
            retailerId: campaignRes?.company_Id,
            campaignId,
            placementId,
            placementName,
            tid: settingsTemplate.identifier,
            pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
          });
        }
        getTrackingEvent({
          event: "ontemplateopen",
          retailerId: campaignRes.company_Id,
          campaignId: campaignRes.id,
          placementId,
          placementName,
          tid: settingsTemplate.identifier,
          pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
        });
      }
    }
    setHasOpen(true);
    // eslint-disable-next-line
  }, [defaultOpen]);

  return (
    <>
      <div
        className={`super-desktop-wrapper ${settings?.location.location}`}
        onClick={handleClickSingleWrapper}
      >
        <div
          className={`super-desktop-popup 
             ${
               !featuredOffer
                 ? `swiper-container-${settings?.mainLayout?.offersNumber || 3}`
                 : ""
             }
              ${
                campaignRes.offers.length < 3
                  ? "super-desktop-popup-" + campaignRes.offers.length
                  : "super-desktop-popup-sixpack"
              }`}
          ref={refOffers}
          style={{
            backgroundColor: settings?.mainLayout.backgroundColor,
            borderColor:
              settings?.mainLayout?.borderColor ||
              settings?.mainLayout.backgroundColor,
            borderRadius: `${settings?.mainLayout.borderRadius}px`,
            transform: `translate(${settings?.location.x || 0}px, ${
              settings?.location.y || 0
            }px)`,
            fontFamily: settings?.mainLayout.fontFamily,
            color: settings?.mainLayout.textColor,
            maxHeight: settings?.location?.y
              ? settings?.location?.y < 0
                ? `calc(100vh - ${settings?.location.y * -1}px)`
                : `calc(100vh - ${settings?.location.y}px)`
              : "",
            padding: `${
              typeof settings?.mainLayout.paddingTopBottom !== "undefined"
                ? settings?.mainLayout.paddingTopBottom
                : 20
            }px ${
              typeof settings?.mainLayout.paddingLeftRight !== "undefined"
                ? settings?.mainLayout.paddingLeftRight
                : 35
            }px`,
          }}
        >
          <div
            className="super-desktop-minimize"
            onClick={() => handleClose()}
            style={{
              backgroundColor: settings?.dealLayout.backgroundColor,
            }}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.176625 0.34468C0.385958 0.133389 0.710902 0.109912 0.945926 0.274249L1.02944 0.34468L7.41698 6.79213L13.9706 0.178277C14.2061 -0.0594256 14.5879 -0.0594256 14.8234 0.178277C15.0327 0.389568 15.056 0.717552 14.8932 0.954775L14.8234 1.03907L8.2698 7.65293L14.5195 13.9609C14.755 14.1986 14.755 14.584 14.5195 14.8217C14.3102 15.033 13.9852 15.0565 13.7502 14.8922L13.6667 14.8217L7.41698 8.51372L1.17826 14.8118C0.942765 15.0495 0.560944 15.0495 0.325445 14.8118C0.116112 14.6005 0.0928526 14.2726 0.255667 14.0353L0.325445 13.951L6.56416 7.65293L0.176625 1.20548C-0.0588749 0.967774 -0.0588749 0.582382 0.176625 0.34468Z"
                fill={settings?.dealLayout.textColor}
              />
            </svg>
          </div>

          <div className={`super-desktop-title`}>
            {buttonShape === ButtonShapes.squareImage && (
              <>
                <div className="super-desktop-title-icon">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.2 10V19H2.8V10M10 19V5.5M10 5.5H5.95C5.35326 5.5 4.78097 5.26295 4.35901 4.84099C3.93705 4.41903 3.7 3.84674 3.7 3.25C3.7 2.65326 3.93705 2.08097 4.35901 1.65901C4.78097 1.23705 5.35326 1 5.95 1C9.1 1 10 5.5 10 5.5ZM10 5.5H14.05C14.6467 5.5 15.219 5.26295 15.641 4.84099C16.0629 4.41903 16.3 3.84674 16.3 3.25C16.3 2.65326 16.0629 2.08097 15.641 1.65901C15.219 1.23705 14.6467 1 14.05 1C10.9 1 10 5.5 10 5.5ZM1 5.5H19V10H1V5.5Z"
                      stroke={settings?.mainLayout.headerTextColor}
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            )}

            {buttonShape === ButtonShapes.squareImageLock && (
              <>
                <div className="super-desktop-title-icon">
                  {displayOnly ? (
                    <svg
                      width="42"
                      height="47"
                      viewBox="0 0 42 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.88889 21.25V12.25C9.88889 9.26631 11.0595 6.40483 13.1433 4.29505C15.227 2.18526 18.0532 1 21 1C23.9469 1 26.773 2.18526 28.8567 4.29505C30.9405 6.40483 32.1111 9.26631 32.1111 12.25V21.25M5.44444 21.25H36.5556C39.0102 21.25 41 23.2647 41 25.75V41.5C41 43.9853 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9853 1 41.5V25.75C1 23.2647 2.98985 21.25 5.44444 21.25Z"
                        stroke={settings?.mainLayout.headerTextColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="42"
                      height="47"
                      viewBox="0 0 42 47"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.88889 21.2554V12.258C9.88613 9.46896 10.9073 6.77834 12.7542 4.7085C14.601 2.63865 17.1419 1.33726 19.8834 1.05697C22.6248 0.776667 25.3714 1.53746 27.5899 3.19164C29.8084 4.84583 31.3406 7.2754 31.8889 10.0087M5.44444 21.2572H36.5556C39.0102 21.2572 41 23.2714 41 25.7559V41.5013C41 43.9859 39.0102 46 36.5556 46H5.44444C2.98985 46 1 43.9859 1 41.5013V25.7559C1 23.2714 2.98985 21.2572 5.44444 21.2572Z"
                        stroke={settings?.mainLayout.headerTextColor}
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </>
            )}

            <span
              className={`super-desktop-title-label`}
              style={{
                fontSize: settings?.mainLayout.headerFontSize + "px",
                fontWeight: settings?.mainLayout.headerFontWeight,
                color: settings?.mainLayout.headerTextColor,
              }}
            >
              {settings?.mainLayout.headerText || ""}
            </span>
          </div>

          <div
            className={`super-desktop-description`}
            style={{
              fontSize: settings?.mainLayout.subHeaderFontSize + "px",
              fontWeight: settings?.mainLayout.subHeaderFontWeight,
              color: settings?.mainLayout.subHeaderTextColor,
            }}
          >
            {settings?.mainLayout.subHeaderText || ""}
          </div>

          {/* {!offerHostType ? (
            <div className="preview-f-closedfilters">
              {!!campaignRes.offers.find((o) => o.offerSettings.isB2C) && (
                <div
                  className="preview-f-button"
                  onClick={() => setOfferHostType("personal")}
                  style={{
                    color: settings?.filters?.activeColor,
                    backgroundColor: settings?.filters?.backgroundColor,
                    borderColor: settings?.filters?.borderColor,
                  }}
                >
                  Show more personal offers
                </div>
              )}
              {!!campaignRes.offers.find((o) => !o.offerSettings.isB2C) && (
                <div
                  className="preview-f-button"
                  onClick={() => setOfferHostType("trade")}
                  style={{
                    color: settings?.filters?.activeColor,
                    backgroundColor: settings?.filters?.backgroundColor,
                    borderColor: settings?.filters?.borderColor,
                  }}
                >
                  Show more trade offers
                </div>
              )}
            </div>
          ) : (
            <div className="preview-f-openfilters">
              {!!campaignRes.offers.find((o) => !o.offerSettings.isB2C) &&
                !!campaignRes.offers.find((o) => o.offerSettings.isB2C) && (
                  <div className="preview-f-filter">
                    <ul
                      style={{
                        borderColor:
                          settings?.filters?.borderColor || "#939393",
                      }}
                    >
                      <li
                        onClick={() => setOfferHostType("personal")}
                        style={{
                          color:
                            offerHostType === "personal"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            offerHostType === "personal"
                              ? settings?.filters?.backgroundColor || "#e5eef5"
                              : "transparent",
                          borderColor:
                            offerHostType === "personal"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : "transparent",
                        }}
                      >
                        Personal offers
                      </li>

                      <li
                        onClick={() => setOfferHostType("trade")}
                        style={{
                          color:
                            offerHostType === "trade"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            offerHostType === "trade"
                              ? settings?.filters?.backgroundColor || "#e5eef5"
                              : "transparent",
                          borderColor:
                            offerHostType === "trade"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : "transparent",
                        }}
                      >
                        Trade offers
                      </li>
                      <li
                        onClick={() => setOfferHostType("all")}
                        style={{
                          color:
                            offerHostType === "all"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            offerHostType === "all"
                              ? settings?.filters?.backgroundColor || "#e5eef5"
                              : "transparent",
                          borderColor:
                            offerHostType === "all"
                              ? settings?.filters?.activeColor || "#0053a0"
                              : "transparent",
                        }}
                      >
                        All
                      </li>
                    </ul>
                  </div>
                )}
              <div className="preview-f-filter">
                <ul
                  style={{
                    borderColor: settings?.filters?.borderColor || "#939393",
                  }}
                >
                  {offersCategories.map((category) => {
                    return (
                      <li
                        onClick={() =>
                          setSelectedCategory(
                            category === selectedCategory ? "" : category
                          )
                        }
                        style={{
                          color:
                            category === selectedCategory
                              ? settings?.filters?.activeColor || "#0053a0"
                              : settings?.filters?.textColor || "#939393",
                          backgroundColor:
                            category === selectedCategory
                              ? settings?.filters?.backgroundColor || "#e5eef5"
                              : "transparent",
                          borderColor:
                            category === selectedCategory
                              ? settings?.filters?.activeColor || "#0053a0"
                              : "transparent",
                        }}
                      >
                        {category}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )} */}

          {settings?.filters?.filters && campaign && (
            <TemplateFilters
              campaign={campaign}
              settings={settings}
              setFilteredList={setOfferFilteredList}
            />
          )}

          {displayOnly && campaign?.campaignGeneralOptions?.showAddToOrder && (
            <PreCountAndSelectAll
              offerLength={offerFilteredList.length}
              countText={settings?.otherSettings?.counterText}
              selectText={settings?.otherSettings?.selectedText}
              showCheckboxSelect={
                settings?.otherSettings?.showCheckboxSelect || false
              }
              showSelectAll={settings?.otherSettings?.showSelectAll || false}
              showCount={settings?.otherSettings?.showCounter || false}
              padding="10px 60px 0 60px"
              noOfSelected={offerFilteredList.filter((o) => o.isAdded).length}
              handleSelectAll={handleSelectAll}
              highlightColor={settings?.mainLayout?.highlightColor}
              borderColor={settings?.dealLayout?.borderColor}
            />
          )}

          {featuredOffer && (
            <>
              {settings?.mainLayout?.showFeatured && (
                <div className="super-desktop-feature">
                  {campaign && (
                    <OfferFeatureItem
                      slot={0}
                      settingsTemplate={settingsTemplate}
                      mainSettings={settings?.mainLayout}
                      campaignId={campaignId}
                      placementId={placementId}
                      placementName={placementName}
                      offer={featuredOffer}
                      offerLength={campaign.offers.length}
                      settings={settings?.dealLayout}
                      defaultOpen={defaultOpen}
                      openSingle={openSingle}
                      onAdd={handleAdd}
                      setPreEmail={setPreEmail}
                      preEmail={preEmail || email}
                      displayOnly={displayOnly}
                      campaign={campaign}
                      isAdded={featuredOffer.isAdded || false}
                      retailerId={campaign.company_Id}
                      hasVoucher={
                        (featuredOffer.voucher &&
                          !!featuredOffer.voucher.voucherType) ||
                        false
                      }
                      otherSettings={settings.otherSettings}
                    />
                  )}
                </div>
              )}
            </>
          )}

          {(isLoadMore || settings?.mainLayout?.startAsExpanded) &&
            settings?.mainLayout?.rowsNumber === "2" &&
            offerFilteredList.length >
              Number(settings.mainLayout.offersNumber) && (
              <div
                className={`super-desktop-swiper swiper-column-${
                  settings.mainLayout.offersNumber || 3
                }`}
              >
                {campaign && (
                  <SwiperComponent
                    key="static-swiper"
                    disabled={true}
                    offers={offerFilteredList
                      .filter((f) => f.id !== featuredOffer?.id)
                      .slice(0, Number(settings.mainLayout.offersNumber) || 3)}
                    settings={settings}
                    campaign={campaign}
                    defaultOpen={defaultOpen}
                    settingsTemplate={settingsTemplate}
                    displayOnly={displayOnly}
                    campaignId={campaignId}
                    placementId={placementId}
                    placementName={placementName}
                    openSingle={openSingle}
                    onAdd={handleAdd}
                    setPreEmail={setPreEmail}
                    preEmail={preEmail || email}
                    selectedEmailOfferId={selectedEmailOfferId}
                    setSelectedEmailOfferId={setSelectedEmailOfferId}
                  ></SwiperComponent>
                )}
              </div>
            )}

          <div
            className={`super-desktop-swiper 
              swiper-column-${settings.mainLayout.offersNumber || 3}`}
          >
            {campaign && (
              <SwiperComponent
                key="static-swiper"
                disabled={false}
                offers={offerFilteredList
                  .filter((f) => f.id !== featuredOffer?.id)
                  .slice(
                    (isLoadMore || settings?.mainLayout?.startAsExpanded) &&
                      settings?.mainLayout?.rowsNumber === "2" &&
                      offerFilteredList.length >
                        Number(settings.mainLayout.offersNumber)
                      ? Number(settings.mainLayout.offersNumber) || 3
                      : 0,
                    offerFilteredList.length
                  )}
                settings={settings}
                campaign={campaign}
                defaultOpen={defaultOpen}
                settingsTemplate={settingsTemplate}
                displayOnly={displayOnly}
                campaignId={campaignId}
                placementId={placementId}
                placementName={placementName}
                openSingle={openSingle}
                onAdd={handleAdd}
                setPreEmail={setPreEmail}
                preEmail={preEmail || email}
                selectedEmailOfferId={selectedEmailOfferId}
                setSelectedEmailOfferId={setSelectedEmailOfferId}
              ></SwiperComponent>
            )}
          </div>

          {!isLoadMore &&
            settings?.mainLayout.rowsNumber === "2" &&
            offerFilteredList.length - (featuredOffer ? 1 : 0) >
              Number(settings.mainLayout.offersNumber) && (
              <div className="swiper-load-more">
                <div
                  className="button-load-more"
                  onClick={() => setLoadMore(true)}
                  style={{
                    backgroundColor:
                      settings?.showMore?.backgroundColor || "#383838",
                    color: settings?.showMore?.textColor || "#ffffff",
                    fontWeight: settings?.showMore?.fontWeight,
                    borderRadius: `${settings?.showMore?.rounding}px`,
                    border: `1px solid ${
                      settings?.showMore?.borderColor ||
                      settings?.showMore?.backgroundColor
                    }`,
                  }}
                >
                  {settings?.showMore?.input || "Show more offers"}
                </div>
              </div>
            )}

          <div className="super-desktop-footer">
            {displayOnly ? (
              <>
                {campaign?.campaignGeneralOptions.emailCaptureOnOffers && (
                  <>
                    {!settings?.dealLayout?.icons ||
                    settings?.dealLayout?.icons === TemplateIcons.default ? (
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                          fill={settings?.mainLayout.textColor}
                        />
                      </svg>
                    ) : (
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM13.5 18.5C13.5 19.33 12.83 20 12 20C11.17 20 10.5 19.33 10.5 18.5V10.5C10.5 9.67 11.17 9 12 9C12.83 9 13.5 9.67 13.5 10.5V18.5ZM12 7C11.17 7 10.5 6.33 10.5 5.5C10.5 4.67 11.17 4 12 4C12.83 4 13.5 4.67 13.5 5.5C13.5 6.33 12.83 7 12 7Z"
                          fill={settings?.mainLayout.textColor}
                        />
                      </svg>
                    )}
                    <span>
                      By selecting you will receive by e-mail after purchase
                    </span>
                  </>
                )}
              </>
            ) : (
              <>
                {!settings?.dealLayout?.icons ||
                settings?.dealLayout?.icons === TemplateIcons.default ? (
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                      fill={settings?.mainLayout.textColor}
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.37 0 0 5.37 0 12C0 18.63 5.37 24 12 24C18.63 24 24 18.63 24 12C24 5.37 18.63 0 12 0ZM13.5 18.5C13.5 19.33 12.83 20 12 20C11.17 20 10.5 19.33 10.5 18.5V10.5C10.5 9.67 11.17 9 12 9C12.83 9 13.5 9.67 13.5 10.5V18.5ZM12 7C11.17 7 10.5 6.33 10.5 5.5C10.5 4.67 11.17 4 12 4C12.83 4 13.5 4.67 13.5 5.5C13.5 6.33 12.83 7 12 7Z"
                      fill={settings?.mainLayout.textColor}
                    />
                  </svg>
                )}
                <span>
                  By selecting the option above you will be directed to a third
                  party website. Any use of third party websites are not subject
                  to our Privacy Policy, and we encourage you to also read their
                  privacy statements
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      {single && campaign?.id && (
        <TemplateSingle
          slot={slot}
          settings={settings}
          isInline={false}
          offer={single}
          isSave={isSingleSave}
          isShare={isSingleShare}
          placementId={placementId}
          placementName={placementName}
          settingsTemplate={settingsTemplate}
          campaignObj={campaign}
          displayOnly={displayOnly}
          campaignId={campaign?.id}
          companyId={campaign.company_Id}
          updateCampaign={handleAdd}
          setPreEmail={setPreEmail}
          preEmail={preEmail}
          closeSignle={handleCloseSingle}
        />
      )}
    </>
  );
};

export default PreviewPopupSuper;
