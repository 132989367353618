import React, { useEffect, useRef, useState } from "react";
import {
  getTrackingEvent,
  postTagEmailOffer,
  postTrackingShare,
  getCustomVoucher,
} from "../../../services/offers/offers.service";
import { EMAIL_REGEX } from "../../../utility/regexp";
import {
  Offer,
  QuerySaveEmail,
  SinglePanels,
  AutoEmailStorage,
  CampaignType,
  TemplateOptionSettings,
  Template,
  LayoutType,
  PlacementStage,
  TrackingShare,
  RedeemBy,
  VoucherType,
} from "../../../types/global.types";
import "./style.scss";

import { InlineShareButtons } from "sharethis-reactjs";
import PreSelect from "../components/PreSelect/PreSelect";

interface Props {
  settings: TemplateOptionSettings;
  slot: number;
  settingsTemplate: Template;
  offer: Offer;
  isSave: boolean;
  isShare: boolean;
  placementId: string;
  placementName: string;
  displayOnly: boolean;
  closeSignle?: (emailSent: boolean, id: string) => void;
  campaignId: string;
  companyId: string;
  preEmail?: string;
  campaignObj: CampaignType;
  isInline: boolean;
  setPreEmail?: (email: string) => void;
  updateCampaign?: (offer: Offer, value: boolean, slot: number) => void;
}

const TemplateSingle = ({
  settings,
  offer,
  isSave,
  isShare,
  settingsTemplate,
  displayOnly,
  isInline,
  campaignObj,
  closeSignle,
  campaignId,
  companyId,
  updateCampaign,
  setPreEmail,
  placementId,
  placementName,
  preEmail,
  slot,
}: Props) => {
  const isWowcher: boolean =
    settingsTemplate.identifier === "template-Es-wowcher" ||
    settingsTemplate.identifier === "template-compact-checkbox-wowcher";
  const [panel, setPanel] = useState<SinglePanels>(
    isSave
      ? SinglePanels.autoEmail
      : isShare
      ? SinglePanels.share
      : SinglePanels.overview
  );
  const [single, setSingle] = useState<Offer | undefined>(offer);
  const [isSingleSave, setSingleSave] = useState<boolean>(isSave);
  const refSingle = useRef<HTMLDivElement | null>(null);
  const refAutoEmail = useRef<HTMLInputElement | null>(null);
  const refSaveEmail = useRef<HTMLInputElement | null>(null);
  const [emailSwitch, setEmailSwitch] = useState<boolean>(
    preEmail || campaignObj.campaignGeneralOptions.automaticallyCheckOptIn
      ? true
      : false
  );
  const [emailPopulate, setEmailPopulate] = useState<boolean>(true);
  const [email, setEmail] = useState<string | undefined>(preEmail);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [sentClick, setSentClick] = useState<boolean>(false);
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sharingUrl, setSharingUrl] = useState<string>("");
  const [linkCopied, setLinkCopied] = useState<boolean>(false);

  const [copyCode, setCopyCode] = useState<boolean>(false);
  const [getCode, setGetCode] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const [customVoucher, setCustomVoucher] = useState<string>("");
  const [voucherLoading, setVoucherLoading] = useState<boolean>(false);

  const handleClose = () => {
    closeSignle && closeSignle(emailSent, offer.id);
    // setSingle(undefined);
    if (settingsTemplate.layoutType === LayoutType.embedded) {
      window.top?.postMessage(
        { event: "setEmbeddedIframeSizeBack", load: "" },
        "*"
      );
    } else {
      window.top?.postMessage({ event: "closePopupIframe", load: "" }, "*");
    }
    getTrackingEvent({
      event: "onreadmoreclose",
      offerId: offer.id,
      retailerId: companyId,
      campaignId: campaignId,
      placementId,
      placementName,
      tid: settingsTemplate.identifier,
      slot,
      pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
    });
  };

  const handleCloseSingle = () => {
    handleClose();
  };

  const handleClickSingleWrapper = (event: React.MouseEvent<HTMLElement>) => {
    if (
      event.target instanceof Node &&
      !refSingle.current?.contains(event.target)
    ) {
      handleClose();
    }
  };

  const handleAdd = (offer: Offer, value: boolean, slot: number) => {
    const _single = { ...offer, isAdded: value };
    window.top?.postMessage(
      { event: "singleChange", offer: _single, slot },
      "*"
    );

    if (value) {
      campaignObj.campaignGeneralOptions?.emailCaptureOnOffers &&
        setPanel(SinglePanels.autoEmail);

      setTimeout(() => {
        window.innerWidth > 768 && refAutoEmail.current?.focus();
      });
    }

    if (settingsTemplate.layoutType === LayoutType.embedded) {
      updateCampaign && updateCampaign(offer, value, slot);
      setSingle(
        (prevState: Offer | undefined) =>
          prevState && { ...prevState, isAdded: value }
      );
      value &&
        getTrackingEvent({
          event: "onselectoffer",
          offerId: offer.id,
          retailerId: companyId,
          campaignId: campaignId,
          placementId,
          placementName,
          tid: settingsTemplate.identifier,
          slot,
          pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
        });
    }
  };

  const handleGetGift = (url: string) => {
    window.open(
      url + `&tid=${settingsTemplate.identifier}&slot=${slot}`,
      "_blank"
    );
  };

  const handleToggleSave = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setSingleSave((pS) => !pS);
    setEmail(undefined);
    setEmailSent(false);
    setEmailSwitch(campaignObj.campaignGeneralOptions?.automaticallyCheckOptIn);
  };

  const setAutoEmailStorage = (storage: AutoEmailStorage) => {
    window.top?.postMessage({ event: "setAutoEmail", load: storage }, "*");
  };

  const setPreEmailStorage = (email: string) => {
    window.top?.postMessage({ event: "setPreEmail", load: email }, "*");
  };

  useEffect(() => {
    setEmail(preEmail);
  }, [preEmail]);

  const submitAutoEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSentClick(true);

    if (single && ((email && validEmail) || preEmail)) {
      setLoading(true);
      !single?.isAdded && handleAdd(offer, true, slot);

      const _autoEmailItem: AutoEmailStorage = {
        offerLink: offer.trackingUrl,
        offerId: single.id,
        email: email || preEmail || "",
      };

      setAutoEmailStorage(_autoEmailItem);
      setPreEmail && setPreEmail(email || preEmail || "");

      setTimeout(() => {
        getTrackingEvent({
          event: "onsendpreemailclick",
          retailerId: campaignObj.company_Id,
          campaignId: campaignObj.id,
          placementId,
          placementName,
          offerId: offer.id,
          slot,
          pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
        });

        setEmailSent(true);
        setLoading(false);
      }, 1e3);
    }
  };

  const submitSaveForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSentClick(true);
    if (single && (email || preEmail) && validEmail) {
      setLoading(true);
      const emailParams: QuerySaveEmail = {
        offerid: single.id,
        email: email || preEmail || "",
        offerLink: single.trackingUrl,
        companyId,
        campaignId: campaignId,
        templateId: settingsTemplate.identifier,
        placementId,
        slot,
        orderId: offer.orderId,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      };

      const response = await postTagEmailOffer(emailParams);
      getTrackingEvent({
        event: "onsendemailclick",
        retailerId: companyId,
        campaignId: campaignId,
        offerId: offer.id,
        placementId,
        placementName,
        tid: settingsTemplate.identifier,
        slot,
        pltype: displayOnly ? PlacementStage.pre : PlacementStage.post,
      });

      if (response?.data?.status) {
        setEmailSent(true);
        setPreEmailStorage(email || preEmail || "");
        setPreEmail && setPreEmail(email || preEmail || "");
      } else {
        handleClose();
      }
    }

    setLoading(false);
  };

  // Validate email on change
  useEffect(() => {
    if (offer.isAdded) {
      window.innerWidth > 768 && refAutoEmail.current?.focus();
    }
    if (isSingleSave) {
      window.innerWidth > 768 && refSaveEmail.current?.focus();
    }
    // eslint-disable-next-line
  }, [offer.isAdded, isSingleSave]);

  // Validate email on change
  useEffect(() => {
    setSentClick(false);
    setEmailSent(false);
    if (email) {
      const isValid = EMAIL_REGEX.test(email);
      !single?.isAdded && displayOnly && handleAdd(offer, true, slot);
      setValidEmail(isValid);
    }
    // eslint-disable-next-line
  }, [email]);

  useEffect(() => {
    const handleHref = (url: string) => {
      window.open(url, "_blank");
    };

    const handleHrefClick = (e: any) => {
      const _href = e.target?.href || undefined;
      if (_href) {
        e.preventDefault();
        handleHref(_href);
      }
    };

    window.addEventListener("click", handleHrefClick);

    return () => window.removeEventListener("click", handleHrefClick);
  }, []);

  const getTrackingShareUrl = async () => {
    setLoading(true);
    if (single) {
      const params: TrackingShare = {
        offerid: single.id,
        offerLink: single.trackingUrl,
        companyId,
        campaignId,
        campaignName: campaignObj.name,
        templateId: settingsTemplate.identifier,
        slot: slot,
        placementId,
        placementName,
        pageUrl: campaignObj.placementSettings[0].page,
        orderId: single.orderId,
        shareType: "sharemanual",
      };

      const response = await postTrackingShare(params);

      if (response?.data?.status) {
        setSharingUrl(response.data.result);
      } else {
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (panel === SinglePanels.share) {
      getTrackingShareUrl();

      const elm = document.getElementById("share-panel");
      elm && elm.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [panel]);

  const handleCopyHtml = (link: string) => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
    setTimeout(() => {
      setLinkCopied(false);
    }, 3e3);
  };

  const handleCopyHtmlVoucher = () => {
    window.focus();
    navigator.clipboard?.writeText(
      customVoucher ? customVoucher : offer.voucher?.code
    );
    setCopied(true);
    setCopyCode(false);
    setTimeout(() => {
      setCopied(false);
    }, 1000); // Show the message for 1 second
  };

  const handleGetCode = async () => {
    setVoucherLoading(true);
    const customVoucher = await getCustomVoucher({
      offerId: offer.id,
      campaignId: campaignId,
    });
    setCustomVoucher(customVoucher?.data?.result);
    setGetCode(true);
    setVoucherLoading(false);
  };

  const getVoucherText = () => {
    return customVoucher ? (
      <>
        <span style={{ fontWeight: "400", marginRight: "3px" }}>Code: </span>

        <span style={{ fontWeight: "600" }}>{customVoucher}</span>
      </>
    ) : offer.voucher?.code ? (
      <>
        <span style={{ fontWeight: "400", marginRight: "3px" }}>Code: </span>
        <span style={{ fontWeight: "600" }}>{offer.voucher?.code}</span>
      </>
    ) : (
      <span style={{ fontWeight: "400" }}>Sorry, code just expired!</span>
    );
  };

  return (
    <>
      {single && settings && (
        <div
          onClick={handleClickSingleWrapper}
          className={`opl-wrapper opl-wrapper-center `}
        >
          <>
            <div
              className="single-box"
              ref={refSingle}
              style={{
                backgroundColor: settings.mainLayout.backgroundColor,
                color: settings.mainLayout.textColor,
                fontFamily: settings.mainLayout.fontFamily,
                borderRadius: `${settings.mainLayout.borderRadius}px`,
              }}
            >
              <div
                className="single-close"
                onClick={() => handleCloseSingle()}
                style={{ backgroundColor: settings.dealLayout.backgroundColor }}
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.176625 0.34468C0.385958 0.133389 0.710902 0.109912 0.945926 0.274249L1.02944 0.34468L7.41698 6.79213L13.9706 0.178277C14.2061 -0.0594256 14.5879 -0.0594256 14.8234 0.178277C15.0327 0.389568 15.056 0.717552 14.8932 0.954775L14.8234 1.03907L8.2698 7.65293L14.5195 13.9609C14.755 14.1986 14.755 14.584 14.5195 14.8217C14.3102 15.033 13.9852 15.0565 13.7502 14.8922L13.6667 14.8217L7.41698 8.51372L1.17826 14.8118C0.942765 15.0495 0.560944 15.0495 0.325445 14.8118C0.116112 14.6005 0.0928526 14.2726 0.255667 14.0353L0.325445 13.951L6.56416 7.65293L0.176625 1.20548C-0.0588749 0.967774 -0.0588749 0.582382 0.176625 0.34468Z"
                    fill={settings.dealLayout.textColor}
                  />
                </svg>
              </div>
              <div className="offer-side">
                <div className="side-image" style={{ position: "relative" }}>
                  <img
                    src={single?.imageUrl}
                    alt={single?.title}
                    style={{
                      borderRadius: `${settings.mainLayout.borderRadius}px`,
                    }}
                  />
                  {offer.voucher?.voucherType && (
                    <span
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0",
                        fontSize: "11px",
                        color: settings?.dealLayout?.buttonTextColor,
                        backgroundColor:
                          settings?.dealLayout?.buttonBackgroundColor,
                        borderRadius: `0 0 ${settings.mainLayout.borderRadius}px ${settings.mainLayout.borderRadius}px`,
                        fontWeight: "600",
                        textAlign: "center",
                        width: "100%",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onMouseEnter={() => setCopyCode(true)}
                      onMouseLeave={() => {
                        setCopyCode(false);
                      }}
                      onClick={() => handleCopyHtmlVoucher()}
                    >
                      {offer.voucher?.voucherType === VoucherType.CUSTOM &&
                      !getCode ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                          className="copy-box"
                        >
                          Code:
                          <svg
                            style={{
                              marginLeft: "5px",
                            }}
                            width="70"
                            height="16"
                            viewBox="0 0 70 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="70" height="16" fill="" />
                            <circle
                              cx="4"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="10"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="52"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="58"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="16"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="22"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="28"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="34"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="40"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                            <circle
                              cx="46"
                              cy="8"
                              r="2"
                              fill={
                                settings?.dealLayout?.buttonTextColor || "white"
                              }
                            />
                          </svg>
                        </div>
                      ) : (
                        <>
                          {copyCode ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                fontWeight: "400",
                              }}
                              onClick={handleCopyHtmlVoucher}
                              className="copy-box"
                            >
                              {copied ? (
                                <>
                                  <svg
                                    width="8"
                                    height="6"
                                    viewBox="0 0 8 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginRight: "5px",
                                    }}
                                  >
                                    <path
                                      d="M1 3L3 5L7 1"
                                      stroke={
                                        settings?.dealLayout?.buttonTextColor ||
                                        "white"
                                      }
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Copied successfully
                                </>
                              ) : (
                                <>
                                  <svg
                                    width="11"
                                    height="10"
                                    viewBox="0 0 11 10"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginRight: "5px",
                                    }}
                                  >
                                    <path
                                      d="M7.75 3V2C7.75 1.73478 7.63147 1.48043 7.42049 1.29289C7.20952 1.10536 6.92337 1 6.625 1H2.125C1.82663 1 1.54048 1.10536 1.3295 1.29289C1.11853 1.48043 1 1.73478 1 2V6C1 6.26522 1.11853 6.51957 1.3295 6.70711C1.54048 6.89464 1.82663 7 2.125 7H3.25M4.375 3H8.875C9.49632 3 10 3.44772 10 4V8C10 8.55228 9.49632 9 8.875 9H4.375C3.75368 9 3.25 8.55228 3.25 8V4C3.25 3.44772 3.75368 3 4.375 3Z"
                                      stroke={
                                        settings?.dealLayout?.buttonTextColor ||
                                        "white"
                                      }
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Copy Code
                                </>
                              )}
                            </div>
                          ) : copied ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                fontWeight: "400",
                              }}
                              className="copy-box"
                            >
                              <svg
                                width="8"
                                height="6"
                                viewBox="0 0 8 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  marginRight: "5px",
                                }}
                              >
                                <path
                                  d="M1 3L3 5L7 1"
                                  stroke={
                                    settings?.dealLayout?.buttonTextColor ||
                                    "white"
                                  }
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Copied successfully
                            </div>
                          ) : (
                            getVoucherText()
                          )}
                        </>
                      )}
                    </span>
                  )}
                </div>

                {!displayOnly ? (
                  !isWowcher && (
                    <>
                      <div className="single-side-actions">
                        {offer?.voucher?.voucherType === VoucherType.CUSTOM &&
                        !getCode ? (
                          <div
                            className="dashed-button-template-settings copy-box important-margin"
                            onClick={handleGetCode}
                            style={{
                              fontSize: `${settings?.dealLayout.buttonFontSize}px`,
                              margin: "0px !important",
                              borderRadius: `${
                                typeof settings?.mainLayout?.borderRadius ===
                                "number"
                                  ? settings?.mainLayout?.borderRadius
                                  : 10
                              }px`,
                            }}
                          >
                            {voucherLoading ? (
                              <svg
                                className="spin"
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                            ) : (
                              "Get code"
                            )}
                          </div>
                        ) : (
                          offer.offerSettings.redeemBy ===
                            RedeemBy.webemail && (
                            <div
                              className="single-action-get"
                              style={{
                                color: settings.dealLayout.buttonTextColor,
                                fontSize: `${settings.dealLayout.buttonFontSize}px`,
                                fontWeight:
                                  settings.dealLayout.buttonFontWeight,
                                backgroundColor:
                                  settings.dealLayout.buttonBackgroundColor,
                                borderRadius:
                                  settings.dealLayout.buttonRounding,
                                border: `1px solid ${
                                  settings.dealLayout.buttonBorderColor ||
                                  settings.dealLayout.buttonBackgroundColor
                                }`,
                                width: "160px",
                                textAlign: "center",
                              }}
                              onClick={() => handleGetGift(single.trackingUrl)}
                            >
                              {single?.offerCTAText || "Get offer now"}
                            </div>
                          )
                        )}
                        {campaignObj.campaignGeneralOptions?.saveOfferOptions
                          .allowSavingOffers &&
                          campaignObj.campaignGeneralOptions?.saveOfferOptions
                            .allowEmail && (
                            <div
                              className="action-save"
                              onClick={handleToggleSave}
                              style={{
                                color: settings.mainLayout.textColor,
                                opacity: isSingleSave ? 0.5 : 1,
                              }}
                            >
                              <svg
                                width="14"
                                height="11"
                                viewBox="0 0 14 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 2.125C13 1.50625 12.46 1 11.8 1H2.2C1.54 1 1 1.50625 1 2.125M13 2.125V8.875C13 9.49375 12.46 10 11.8 10H2.2C1.54 10 1 9.49375 1 8.875V2.125M13 2.125L7 6.0625L1 2.125"
                                  stroke={settings.mainLayout.textColor}
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              Email me
                            </div>
                          )}
                      </div>
                    </>
                  )
                ) : (
                  <>
                    {campaignObj.campaignGeneralOptions?.showAddToOrder && (
                      <div className="single-side-actions">
                        <PreSelect
                          onClick={() => {
                            handleAdd(single, !single.isAdded, slot);
                          }}
                          offerId={offer.id}
                          buttonFontWeight={
                            settings?.dealLayout.buttonFontWeight
                          }
                          buttonFontSize={settings?.dealLayout.buttonFontSize}
                          showCheckboxSelect={
                            settings?.otherSettings?.showCheckboxSelect
                          }
                          checked={single.isAdded || false}
                          highlightColor={settings?.mainLayout?.highlightColor}
                          borderColor={settings?.dealLayout?.borderColor}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="offer-content">
                <div
                  className={`content-title ${
                    settingsTemplate.identifier ===
                      "template-compact-checkbox-wowcher" && "title-padding-top"
                  }`}
                >
                  <span style={{ color: settings.mainLayout.headerTextColor }}>
                    {single?.title}
                  </span>
                </div>

                {!isSingleSave ? (
                  <>
                    <div className="content">
                      {(single?.longDescription || single.description) && (
                        <div
                          className="content-panel"
                          style={{
                            color: settings.dealLayout.textColor,
                            backgroundColor:
                              settings.dealLayout.backgroundColor,
                            borderRadius: `${settings.mainLayout.borderRadius}px`,
                          }}
                        >
                          <div
                            className="panel-header"
                            onClick={() => setPanel(SinglePanels.overview)}
                          >
                            <span>Overview</span>
                            <svg
                              className={
                                panel === SinglePanels.overview ? "rotate" : ""
                              }
                              width="11"
                              height="6"
                              viewBox="0 0 11 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                fill={settings.dealLayout.textColor}
                              />
                            </svg>
                          </div>

                          {panel === SinglePanels.overview && (
                            <div
                              className="panel-body"
                              dangerouslySetInnerHTML={{
                                __html:
                                  single.longDescription || single.description,
                              }}
                            ></div>
                          )}
                        </div>
                      )}

                      {single?.faqs && (
                        <div
                          className="content-panel"
                          style={{
                            color: settings.dealLayout.textColor,
                            backgroundColor:
                              settings.dealLayout.backgroundColor,
                            borderRadius: `${settings.mainLayout.borderRadius}px`,
                          }}
                        >
                          <div
                            className="panel-header"
                            onClick={() => setPanel(SinglePanels.faqs)}
                          >
                            <span>FAQs</span>
                            <svg
                              className={
                                panel === SinglePanels.faqs ? "rotate" : ""
                              }
                              width="11"
                              height="6"
                              viewBox="0 0 11 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                fill={settings.dealLayout.textColor}
                              />
                            </svg>
                          </div>
                          {panel === SinglePanels.faqs && (
                            <div
                              className="panel-body"
                              dangerouslySetInnerHTML={{ __html: single?.faqs }}
                            ></div>
                          )}
                        </div>
                      )}
                      {single?.terms && (
                        <div
                          className="content-panel"
                          style={{
                            color: settings.dealLayout.textColor,
                            backgroundColor:
                              settings.dealLayout.backgroundColor,
                            borderRadius: `${settings.mainLayout.borderRadius}px`,
                          }}
                        >
                          <div
                            className="panel-header"
                            onClick={() => setPanel(SinglePanels.terms)}
                          >
                            <span>Terms</span>
                            <svg
                              className={
                                panel === SinglePanels.terms ? "rotate" : ""
                              }
                              width="11"
                              height="6"
                              viewBox="0 0 11 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                fill={settings.dealLayout.textColor}
                              />
                            </svg>
                          </div>
                          {panel === SinglePanels.terms && (
                            <div
                              className="panel-body"
                              dangerouslySetInnerHTML={{
                                __html: single?.terms,
                              }}
                            ></div>
                          )}
                        </div>
                      )}
                      {displayOnly &&
                        campaignObj.campaignGeneralOptions
                          ?.emailCaptureOnOffers &&
                        campaignObj.campaignGeneralOptions
                          .emailCaptureOnAddToOrder && (
                          <div
                            className="content-panel"
                            style={{
                              color: settings.dealLayout.textColor,
                              backgroundColor:
                                settings.dealLayout.backgroundColor,
                              borderRadius: `${settings.mainLayout.borderRadius}px`,
                            }}
                          >
                            <div
                              className="panel-header"
                              onClick={() => {
                                setPanel(SinglePanels.autoEmail);
                                setTimeout(() => {
                                  window.innerWidth > 768 &&
                                    refAutoEmail.current?.focus();
                                });
                              }}
                            >
                              <span>Email</span>
                              <svg
                                className={
                                  panel === SinglePanels.autoEmail
                                    ? "rotate"
                                    : ""
                                }
                                width="11"
                                height="6"
                                viewBox="0 0 11 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                  fill={settings.dealLayout.textColor}
                                />
                              </svg>
                            </div>
                            {panel === SinglePanels.autoEmail && (
                              <div
                                className="panel-body"
                                style={{ color: settings.dealLayout.textColor }}
                              >
                                <form onSubmit={submitAutoEmail}>
                                  <div className="content-panel-email-input">
                                    <div
                                      className="content-panel-input-description"
                                      style={{
                                        color: settings.dealLayout.textColor,
                                      }}
                                    >
                                      Enter your email and we will email you
                                      after you make a purchase
                                    </div>
                                    <div className="content-panel-input">
                                      <input
                                        type="text"
                                        ref={refAutoEmail}
                                        onChange={(e) => {
                                          setEmail(e.target.value);
                                          setEmailPopulate(false);
                                        }}
                                        value={
                                          emailPopulate
                                            ? preEmail || email || ""
                                            : email || ""
                                        }
                                        placeholder="Enter your email..."
                                        style={{
                                          borderRadius: `${
                                            typeof settings?.mainLayout
                                              ?.borderRadius === "number"
                                              ? settings?.mainLayout
                                                  ?.borderRadius
                                              : 10
                                          }px`,
                                        }}
                                      />
                                    </div>
                                    <div className="label-wrapper">
                                      {emailSent &&
                                        typeof email === "undefined" && (
                                          <span className="label-success">
                                            {" "}
                                            Email will be sent automatically
                                          </span>
                                        )}
                                      {!validEmail && email && sentClick && (
                                        <span className="label-error">
                                          Must be valid email!
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="content-panel-submit">
                                    <div className="submit-checkbox">
                                      <label
                                        className="label-message"
                                        htmlFor="accept-email"
                                        style={{
                                          color: settings?.dealLayout.textColor,
                                        }}
                                      >
                                        <input
                                          id="accept-email"
                                          type="checkbox"
                                          checked={emailSwitch}
                                          onChange={(e) =>
                                            setEmailSwitch(e.target.checked)
                                          }
                                        />
                                        <div className="checkboxsvg"></div>I
                                        have read and agree to the{" "}
                                        <a
                                          href="https://brandswap.com/privacy/"
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          style={{
                                            color:
                                              settings.dealLayout.textColor,
                                          }}
                                        >
                                          Privacy Policy
                                        </a>
                                      </label>
                                    </div>
                                    <button
                                      type="submit"
                                      className="button"
                                      style={{
                                        borderRadius: `${
                                          typeof settings?.mainLayout
                                            ?.borderRadius === "number"
                                            ? settings?.mainLayout?.borderRadius
                                            : 10
                                        }px`,
                                      }}
                                      disabled={
                                        isLoading
                                          ? true
                                          : preEmail && emailPopulate
                                          ? !emailSwitch
                                          : !validEmail || !emailSwitch
                                      }
                                    >
                                      Save
                                      {isLoading && (
                                        <svg
                                          className="spin"
                                          viewBox="0 0 1024 1024"
                                          focusable="false"
                                          data-icon="loading"
                                          width="1em"
                                          height="1em"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                        </svg>
                                      )}
                                      {emailSent && !isLoading && (
                                        <svg
                                          width="12"
                                          height="9"
                                          viewBox="0 0 12 9"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.5667 0.28139C11.9134 0.644571 11.9 1.22002 11.5368 1.56669L4.87009 7.93033C4.51879 8.26565 3.96597 8.26565 3.61468 7.93032L0.281383 4.7485C-0.0817948 4.40183 -0.095174 3.82638 0.2515 3.4632C0.598174 3.10002 1.17362 3.08664 1.5368 3.43332L4.24239 6.01596L10.2814 0.251494C10.6446 -0.0951766 11.22 -0.0817918 11.5667 0.28139Z"
                                            fill="#ffffff"
                                          />
                                        </svg>
                                      )}
                                    </button>
                                  </div>
                                </form>
                              </div>
                            )}
                          </div>
                        )}

                      {!displayOnly &&
                        campaignObj.sharingOptions?.allowSharing &&
                        campaignObj.sharingOptions.sharingPlatforms?.platforms
                          ?.length > 0 &&
                        single?.offerSettings?.allowShare && (
                          <div
                            className="content-panel"
                            id="share-panel"
                            style={{
                              color: settings.dealLayout.textColor,
                              backgroundColor:
                                settings.dealLayout.backgroundColor,
                              borderRadius: `${settings.mainLayout.borderRadius}px`,
                              minHeight:
                                panel === SinglePanels.share ? "225px" : "auto",
                            }}
                          >
                            <div
                              className="panel-header"
                              onClick={() => setPanel(SinglePanels.share)}
                            >
                              <span>Sharing options</span>
                              <svg
                                className={
                                  panel === SinglePanels.share ? "rotate" : ""
                                }
                                width="11"
                                height="6"
                                viewBox="0 0 11 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.78033 5.78033C1.48744 6.07323 1.01256 6.07323 0.71967 5.78033C0.426777 5.48744 0.426777 5.01256 0.71967 4.71967L5.21967 0.219671C5.5036 -0.0642633 5.96079 -0.0741975 6.25679 0.197135L10.7568 4.32214C11.0621 4.60203 11.0828 5.07646 10.8029 5.3818C10.523 5.68713 10.0485 5.70776 9.74321 5.42787L5.77256 1.7881L1.78033 5.78033Z"
                                  fill={settings.dealLayout.textColor}
                                />
                              </svg>
                            </div>

                            {panel === SinglePanels.share && (
                              <div className="panel-body">
                                {isLoading && (
                                  <div className="icon-spin">
                                    <svg
                                      className="spin"
                                      viewBox="0 0 1024 1024"
                                      focusable="false"
                                      data-icon="loading"
                                      width="1em"
                                      height="1em"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                                    </svg>
                                  </div>
                                )}

                                <div className="share-platforms">
                                  {sharingUrl && (
                                    <>
                                      <InlineShareButtons
                                        config={{
                                          alignment: "center", // alignment of buttons (left, center, right)
                                          color: "social", // set the color of buttons (social, white)
                                          enabled: true, // show/hide buttons (true, false)
                                          font_size: 16, // font size for the buttons
                                          labels: "cta", // button labels (cta, counts, null)
                                          language: "en", // which language to use (see LANGUAGES)
                                          networks:
                                            campaignObj.sharingOptions
                                              .sharingPlatforms?.platforms,
                                          padding: 12, // padding within buttons (INTEGER)
                                          radius: 4, // the corner radius on each button (INTEGER)
                                          show_total: false,
                                          size: 40, // the size of each button (INTEGER)
                                          // OPTIONAL PARAMETERS
                                          url: sharingUrl, // (defaults to current url)
                                          image: single.imageUrl, // (defaults to og:image or twitter:image)
                                          description: single.title, // (defaults to og:description or twitter:description)
                                          title: single.title, // (defaults to og:title or twitter:title)
                                          message: single.title, // (only for email sharing)
                                          subject: single.title, // (only for email sharing)
                                          username: "BrandSwap", // (only for twitter sharing)
                                        }}
                                      />
                                    </>
                                  )}
                                </div>

                                {sharingUrl && (
                                  <div
                                    className="share-link"
                                    onClick={() => handleCopyHtml(sharingUrl)}
                                  >
                                    <div
                                      className="share-link-icon"
                                      style={{
                                        backgroundColor:
                                          settings.mainLayout.backgroundColor,
                                      }}
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="link"
                                        width="1em"
                                        height="1em"
                                        fill={settings.mainLayout.textColor}
                                        aria-hidden="true"
                                      >
                                        <path d="M574 665.4a8.03 8.03 0 00-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 00-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 000 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 000 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 00-11.3 0L372.3 598.7a8.03 8.03 0 000 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z"></path>
                                      </svg>
                                    </div>
                                    <span className="share-link-label">
                                      Copy link
                                    </span>

                                    {linkCopied && (
                                      <span className="share-link-copied">
                                        Copied
                                        <svg
                                          width="12"
                                          height="9"
                                          viewBox="0 0 12 9"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M11.5667 0.28139C11.9134 0.644571 11.9 1.22002 11.5368 1.56669L4.87009 7.93033C4.51879 8.26565 3.96597 8.26565 3.61468 7.93032L0.281383 4.7485C-0.0817948 4.40183 -0.095174 3.82638 0.2515 3.4632C0.598174 3.10002 1.17362 3.08664 1.5368 3.43332L4.24239 6.01596L10.2814 0.251494C10.6446 -0.0951766 11.22 -0.0817918 11.5667 0.28139Z"
                                            fill="#292426"
                                          />
                                        </svg>
                                      </span>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}

                      <div className="content-extra">
                        {/* <a href="https://www.brandswap.com" rel="noopener noreferrer" target="_blank" style={{ color: settings.mainLayout.textColor }}>
                                        Powered by BrandSwap
                                    </a> */}
                        <span
                          style={{ color: settings.mainLayout.headerTextColor }}
                        >
                          Powered by BrandSwap
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="content">
                      <form
                        className={`content-panel`}
                        onSubmit={submitSaveForm}
                        style={{
                          color: settings.dealLayout.textColor,
                          backgroundColor: settings.dealLayout.backgroundColor,
                          borderRadius: `${settings.mainLayout.borderRadius}px`,
                        }}
                      >
                        {/* {isLoading &&
                                                <div className="icon-spin">
                                                    <svg className="spin" viewBox="0 0 1024 1024" focusable="false" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
                                                </div>
                                            } */}

                        {/* <div className="content-panel-email-switch">
                                            <div className="opl-switch-wrapper">
                                                <label>
                                                    <input className="opl-input" type="checkbox" checked={emailSwitch} onChange={(e) => setEmailSwitch(e.target.checked)} />
                                                    <span className="opl-switch-toggle" style={{ backgroundColor: settings.mainLayout.textColor }}>
                                                        <span className="opl-switch-toggle-before" style={{ backgroundColor: deviceSettings?.general.backgroundColor }}></span>
                                                    </span>
                                                    <span className="opl-switch-text">Email</span>
                                                </label>
                                            </div>
                                        </div> */}
                        <div className="content-panel-email-input">
                          <div
                            className="content-panel-input-title"
                            style={{ color: settings.dealLayout.textColor }}
                          >
                            {settings?.dealLayout.emailMeTheGift ||
                              "Email me the offer"}
                          </div>
                          <div className="content-panel-input">
                            <input
                              type="text"
                              ref={refSaveEmail}
                              onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailPopulate(false);
                              }}
                              style={{
                                borderRadius: `${settings.mainLayout.borderRadius}px`,
                              }}
                              value={
                                emailPopulate
                                  ? preEmail || email || ""
                                  : email || ""
                              }
                              placeholder={
                                settings?.dealLayout.enterEmail ||
                                "Enter your email..."
                              }
                            />
                          </div>
                          <div className="label-wrapper">
                            {emailSent && (
                              <span className="label-success">
                                {offer.offerSettings.redeemBy ===
                                RedeemBy.email ? (
                                  <>
                                    {offer.offerSettings.delaySetting
                                      .delayValue === "0" ||
                                    offer.offerSettings.delaySetting
                                      .delayType === "none" ? (
                                      <>
                                        {" "}
                                        Thank you! We have emailed your offer!
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        Thank you! You will receive the offer in{" "}
                                        {
                                          offer.offerSettings.delaySetting
                                            .delayValue
                                        }{" "}
                                        {
                                          offer.offerSettings.delaySetting
                                            .delayType
                                        }
                                        !
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>Email sent</>
                                )}
                              </span>
                            )}
                            {!validEmail && email && sentClick && (
                              <span className="label-error">
                                Must be valid email!
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="content-panel-submit">
                          <div className="item-email-info">
                            Your data will be processed according to BrandSwap's
                            <a
                              href="https://brandswap.com/privacy/"
                              rel="noopener noreferrer"
                              target="_blank"
                              style={{
                                color:
                                  settings?.dealLayout.emailTextColor ||
                                  "#888888",
                              }}
                            >
                              {" "}
                              privacy policy
                            </a>
                          </div>
                          <div className="submit-checkbox">
                            <label
                              className="label-message"
                              htmlFor="accept-email"
                              style={{ color: settings.dealLayout.textColor }}
                            >
                              <input
                                id="accept-email"
                                type="checkbox"
                                checked={emailSwitch}
                                onChange={(e) =>
                                  setEmailSwitch(e.target.checked)
                                }
                              />
                              <div className="checkboxsvg"></div>I agree to
                              share my email address with BrandSwap to receive
                              details about this offer
                            </label>
                          </div>
                          <button
                            type="submit"
                            className="button"
                            style={{
                              borderRadius: `${
                                typeof settings?.mainLayout?.borderRadius ===
                                "number"
                                  ? settings?.mainLayout?.borderRadius
                                  : 10
                              }px`,
                            }}
                            disabled={
                              isLoading
                                ? true
                                : preEmail && emailPopulate
                                ? !emailSwitch
                                : !emailSwitch
                            }
                          >
                            Send
                            {isLoading && (
                              <svg
                                className="spin"
                                viewBox="0 0 1024 1024"
                                focusable="false"
                                data-icon="loading"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path>
                              </svg>
                            )}
                            {emailSent && !isLoading && (
                              <svg
                                width="12"
                                height="9"
                                viewBox="0 0 12 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M11.5667 0.28139C11.9134 0.644571 11.9 1.22002 11.5368 1.56669L4.87009 7.93033C4.51879 8.26565 3.96597 8.26565 3.61468 7.93032L0.281383 4.7485C-0.0817948 4.40183 -0.095174 3.82638 0.2515 3.4632C0.598174 3.10002 1.17362 3.08664 1.5368 3.43332L4.24239 6.01596L10.2814 0.251494C10.6446 -0.0951766 11.22 -0.0817918 11.5667 0.28139Z"
                                  fill="#ffffff"
                                />
                              </svg>
                            )}
                          </button>
                        </div>
                      </form>

                      <div className="content-extra">
                        {/* <a href="https://www.brandswap.com" rel="noopener noreferrer" target="_blank" style={{ color: settings.mainLayout.textColor }}>
                                        Powered by BrandSwap
                                    </a> */}
                        <span
                          style={{ color: settings.mainLayout.headerTextColor }}
                        >
                          Powered by BrandSwap
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default TemplateSingle;
