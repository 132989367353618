import { useEffect, useState } from "react";
import {
  CampaignType,
  LayoutClass,
  LayoutDeviceSettings,
  Offer,
  TemplateOptionSettings,
} from "../../types/global.types";
import LayoutEmail from "../layout/email";
import LayoutError from "../layout/emailError";

const WrapperEmail = () => {
  const [templateSettings, setTemplateSettings] =
    useState<TemplateOptionSettings>();
  const [settings, setSettings] = useState<LayoutDeviceSettings>();
  const [campaignObj, setCampaign] = useState<CampaignType>();

  const [emailPopupOffer, setEmailPopupOffer] = useState<Offer | undefined>(
    undefined
  );
  const [preEmail, setPreEmail] = useState<string>();
  const [campaignId, setCampaignId] = useState<string>("");
  const [placementId, setPlacementId] = useState<string>("");
  const [placementName, setPlacementName] = useState<string>("");
  const [offerSlot, setOfferSlot] = useState<number>(0);

  useEffect(() => {
    window.addEventListener("message", function (event) {
      if (Object.keys(event.data).includes("extra")) {
        setSettings(event?.data.extra.settings);
        setCampaign(event?.data.extra.campaign);
        setEmailPopupOffer(event?.data.extra.offer);
        setCampaignId(event.data.extra.campaignId);
        setPlacementName(event?.data.extra.placementName);
        setPlacementId(event?.data.extra.placementId);
        setOfferSlot(event?.data.extra.slot);
        event.data.extra?.preEmail && setPreEmail(event.data.extra?.preEmail);
      }
    });

    return () => window.removeEventListener("message", () => {});
  });

  useEffect(() => {
    if (
      settings?.settings &&
      settings?.template &&
      settings?.template.identifier !== "templateUlt"
    ) {
      setTemplateSettings(settings.settings);
    }
  }, [settings]);

  return (
    <>
      {settings && emailPopupOffer && campaignObj ? (
        <LayoutEmail
          settings={
            settings?.type === LayoutClass.action
              ? settings.action
              : settings.popup
          }
          templateSettings={templateSettings}
          emailPopupOffer={emailPopupOffer}
          prePopulateEmail={preEmail}
          campaignObj={campaignObj}
          campaignId={campaignId}
          placementId={placementId}
          placementName={placementName}
          slot={offerSlot}
        ></LayoutEmail>
      ) : (
        <>
          <LayoutError></LayoutError>
        </>
      )}
    </>
  );
};

export default WrapperEmail;
